<template>
<Transition name="slide-fade" appear>
    <div class="grid md:grid-cols-2 w-full mb-6 border rounded h-1/2"  :class="{ 'md:grid-cols-3': scopeCheck('view_all_customers') }" style="min-height: 80vh;">
        <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
			id="raiseDisputeModal" tabindex="-1" aria-labelledby="raiseDisputeModalTitle" aria-modal="true"
			role="dialog">
			<div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
				<div
					class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
					<div
						class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
						<h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
							Raise Dispute
						</h5>
						<button id="disputeClose" type="button"
							class="btn-close box-content w-4 h-4 p-1 text-t3 border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
							data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body relative p-4">
						<p>Clarify Dispute Reason</p>
						<textarea v-model="disputeReason" rows="6" placeholder="Type the reason of dispute in four or less lines."
							class="form-control mt-6 block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" ></textarea>
						<p class="mt-6">Customer Medium</p>
						<input v-model="staffReason" type="text" placeholder="Type the customer medium"
							class="form-control mt-6 block w-full p-3 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
							
					</div>
					<div
						class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
						<button @click="reqSend" type="button"
							class="inline-block px-6 py-2.5 bg-t3 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-light hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
							Raise Dispute
						</button>
					</div>
				</div>
			</div>
		</div>
        <div v-if="scopeCheck('view_all_customers')" class="hidden md:block col-span-1 bg-white border-r p-2 border-gray-200 ">
            <div class="my-3 mx-3 ">
                <h2 class="ml-2 mb-2 text-gray-600 text-lg font-bold my-2">Customers</h2>
                <div class="relative text-gray-600 focus-within:text-gray-400">
                    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6 text-gray-500">
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                    </span>
                    <input v-model="search" @input="debCheck()" aria-placeholder="Search" placeholder="Search"
                        class="py-2 pl-10 block w-full rounded bg-gray-100 outline-none focus:text-gray-700"
                        type="search" name="search" required autocomplete="search" />
                </div>
            </div>

            <!-- List of all customers -->

            <ul class="overflow-y-scroll" style="max-height: calc(80vh - (223px));" >
                <li>
                    <a v-for="customer in customers" :key="customer.id" @click="changeUser(customer.id)"
                        class="hover:bg-gray-100 border-b border-gray-200 px-3 py-2 cursor-pointer flex items-center text-sm focus:outline-none focus:border-gray-200 transition duration-150 ease-in-out">
                        <img class="h-10 w-10 rounded object-cover" :src="filteredImage(customer)"  />
                        <div class="pb-2 flex items-start justify-between w-full overflow-hidden">
                            <div class="pl-3 w-3/4">
                                <span class="block ml-2 truncate overflow-hidden text-base text-t3">{{customer.first_name}} <span class="uppercase">{{customer.last_name}}</span> </span>
                                <span class="block ml-2 text-sm text-gray-600">{{customer.customer_id}}</span>
                            </div>
                            <!-- <span class="block ml-2 text-sm text-gray-600">5 minutes</span> -->
                        </div>
                    </a>

                </li>
            </ul>
            
            <div class="flex flex-col justify-center w-full py-4 gap-4 items-center">
							<div class="flex justify-center w-full gap-4 items-center">
                                <button @click="firstPage()" v-if="page>1" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
								<div class="flex">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
									</svg>
								</div>
							</button>
							<button @click="previousPage()" v-if="page>1" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
								</svg>
							</button>
							<p class="text-t3 font-medium text-xs 2xl:text-base" v-if="pageCount">Page {{page}} of {{pageCount}}</p>
							<button @click="nextPage()" v-if="isNext" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
								</svg>
							</button>
							<button @click="lastPage()" v-if="isNext" class="text-t3 hover:text-t3-teritiary bg-t3-body p-1 rounded-md"> 
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
								</svg>
							</button>
                            </div>
                            <div >
								<label for="rowscount" class="inline-block mx-2 text-xs 2xl:text-base ">Number of records:</label>
								<select @change="refreshPage()" v-model="numberOfPages" id="rowscount" class="form-control w-12 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"> 
											<option value="3">3</option>
											<option value="10">10</option>
											<option value="25">25</option>
											<option value="50">50</option>
											<option value="100">100</option>
								</select>
							</div>
						</div>
                        
        </div>
        
        <!-- Current customer profile -->

        <div class=" m-auto w-96 md:col-span-2 md:m-0 md:w-full bg-white" style="max-height:80vh;">
        <div v-if="!load" class="m-auto font-semibold text-gray-500 w-full h-full flex items-center justify-center"> Loading user info...</div>
            <div v-if="load" class="w-full">
        
        <!-- Profile Header -->

                <div class="flex justify-between border-b border-gray-200 pl-3 py-3">
                    <div class="flex items-center">
                    <img :src="profileImage" class="w-12 h-12 rounded mr-4" alt="">
                    <div class="flex flex-col justify-center">
                        <h1 class="font-bold text-t3 text-xl mb-1">{{customer.first_name}} <span class="uppercase">{{customer.last_name}}</span></h1> 
                        <p class="  text-t3-mutedText text-lg">{{customer.customer_id}}</p>
                    </div>
                    </div>
                    <div class="my-auto flex gap-2 mr-24 items-center">
                        <router-link :to="{name: 'BookNewUser', params:{user:customer.id}}" v-if="scopeCheck('create_booking') && customer.customerprofile" class="text-t3-teritiary p-2 rounded mr-4" >+ Assessment</router-link>
                        <span v-if="editProf ===  true" class="text-t3 cursor-pointer" @click="editProf = false">Edit</span>
                        <span v-if="editProf === false" class=" text-t3-teritiary cursor-pointer" @click="updateCustomerProfile()" >Submit</span>
                        <span class=" text-t3-cancel cursor-pointer" v-if="editProf === false" @click="cancelProf()" >Cancel</span>
                    </div>
                </div>

        <!-- Profile content -->

                <div id="chat" class="w-full p-6 relative overflow-y-auto" style="max-height: calc(80vh - 96px);"  ref="toolbarChat">
                    
                    <h1 class="font-semibold text-t3">Customer Profile</h1>
                    
                        <div v-if="!editProf" class="w-full p-6 ">
                            <div class="form-floating mb-3">
                                        <input v-model="user.email" type="email" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                        placeholder="example@email.com" id="floatingInput4" />
                                        <label for="floatingInput4" class="text-gray-700" >Email<span class="text-red-600">*</span></label>
                            </div>
                            <div class="xl:flex justify-center items-center w-full">

                                <div class="text-center">
                                    <img v-if="user.profile_img" :src="customer.profile_img !== null ? customer.profile_img:''" id='output-image' class="w-48 h-48 mr-4 border-t3-secondary border my-4 object-cover rounded-xl" alt=""/>
                                    <label v-if="!user.profile_img" class="w-48 h-48 flex mr-4 flex-col items-center px-4 py-12 bg-white text-blue rounded-lg tracking-wide border border-gray-300 border-dashed cursor-pointer hover:border-t3-teritiary hover:text-white-teritiary">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.0" stroke="currentColor" class="w-12 h-12">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                                                        </svg>
                                                        <span class="mt-2 text-base leading-normal">Add a Profile Image</span>
                                                        <FileInput  id="inputfield" ref="fileupload" accept="image/*" @change="attachmentLoad" v-model="user.profile_img"
                                                    class="hidden" />
                                    </label>
                                    <button @click="user.profile_img = null" v-if="user.profile_img" class="text-sm text-t3-cancel mt-2">X Pick Another</button>
                                </div>
                                <div class="w-full xl:w-4/6">
                                    <div class="form-floating mb-1 ">
                                        <input v-model="user.first_name" type="text" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                        placeholder="First Name" id="floatingInput1" />
                                        <label for="floatingInput1" class="text-gray-700" >First Name<span class="text-red-600">*</span></label>
                                    </div>
                                    <div class="form-floating mb-1 ">        
                                        <input v-model="user.middle_name" type="text" class="form-control block w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                            placeholder="Middle Name" id="floatingInput2" />
                                            <label for="floatingInput2" class="text-gray-700" >Middle Name</label>
                                    </div>
                                    <div class="form-floating ">
                                        <input v-model="user.last_name" type="text" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                            placeholder="Last Name" id="floatingInput3"/>
                                            <label for="floatingInput3" class="text-gray-700" >Last Name<span class="text-red-600">*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div class="my-4">
                                <label for="datepcik" class="text-gray-700 text-left text-sm" >Date of Birth<span class="text-red-600">*</span></label>
                                <Datepicker id="datepick" v-model="user.dob" :enableTimePicker="false" :max-date="new Date('1/1/2012')" datePicker placeholder="Select a Date"  class="form-control block w-full  px-3 p-y-1.5 text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"/>
                            </div>
                            <div class="my-4">
                                <label for="id_nationality" class="text-gray-700 text-left text-sm" >Nationality<span class="text-red-600">*</span></label>
                                <select placeholder="Select a Country" class="form-select form-select-lg mb-3
                                    appearance-none
                                    block
                                    w-full
                                    px-4
                                    py-2
                                    text-xl
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding bg-no-repeat
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" v-model="user.nationality" id="id_nationality">
                                <option :value="null" disabled>Select country</option>
                                
                                <option value="AF">Afghanistan</option>
                                
                                <option value="AX">Åland Islands</option>
                                
                                <option value="AL">Albania</option>
                                
                                <option value="DZ">Algeria</option>
                                
                                <option value="AS">American Samoa</option>
                                
                                <option value="AD">Andorra</option>
                                
                                <option value="AO">Angola</option>
                                
                                <option value="AI">Anguilla</option>
                                
                                <option value="AQ">Antarctica</option>
                                
                                <option value="AG">Antigua and Barbuda</option>
                                
                                <option value="AR">Argentina</option>
                                
                                <option value="AM">Armenia</option>
                                
                                <option value="AW">Aruba</option>
                                
                                <option value="AU">Australia</option>
                                
                                <option value="AT">Austria</option>
                                
                                <option value="AZ">Azerbaijan</option>
                                
                                <option value="BS">Bahamas</option>
                                
                                <option value="BH">Bahrain</option>
                                
                                <option value="BD">Bangladesh</option>
                                
                                <option value="BB">Barbados</option>
                                
                                <option value="BY">Belarus</option>
                                
                                <option value="BE">Belgium</option>
                                
                                <option value="BZ">Belize</option>
                                
                                <option value="BJ">Benin</option>
                                
                                <option value="BM">Bermuda</option>
                                
                                <option value="BT">Bhutan</option>
                                
                                <option value="BO">Bolivia</option>
                                
                                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                
                                <option value="BA">Bosnia and Herzegovina</option>
                                
                                <option value="BW">Botswana</option>
                                
                                <option value="BV">Bouvet Island</option>
                                
                                <option value="BR">Brazil</option>
                                
                                <option value="IO">British Indian Ocean Territory</option>
                                
                                <option value="BN">Brunei</option>
                                
                                <option value="BG">Bulgaria</option>
                                
                                <option value="BF">Burkina Faso</option>
                                
                                <option value="BI">Burundi</option>
                                
                                <option value="CV">Cabo Verde</option>
                                
                                <option value="KH">Cambodia</option>
                                
                                <option value="CM">Cameroon</option>
                                
                                <option value="CA">Canada</option>
                                
                                <option value="KY">Cayman Islands</option>
                                
                                <option value="CF">Central African Republic</option>
                                
                                <option value="TD">Chad</option>
                                
                                <option value="CL">Chile</option>
                                
                                <option value="CN">China</option>
                                
                                <option value="CX">Christmas Island</option>
                                
                                <option value="CC">Cocos (Keeling) Islands</option>
                                
                                <option value="CO">Colombia</option>
                                
                                <option value="KM">Comoros</option>
                                
                                <option value="CG">Congo</option>
                                
                                <option value="CD">Congo (the Democratic Republic of the)</option>
                                
                                <option value="CK">Cook Islands</option>
                                
                                <option value="CR">Costa Rica</option>
                                
                                <option value="CI">Côte d&#x27;Ivoire</option>
                                
                                <option value="HR">Croatia</option>
                                
                                <option value="CU">Cuba</option>
                                
                                <option value="CW">Curaçao</option>
                                
                                <option value="CY">Cyprus</option>
                                
                                <option value="CZ">Czechia</option>
                                
                                <option value="DK">Denmark</option>
                                
                                <option value="DJ">Djibouti</option>
                                
                                <option value="DM">Dominica</option>
                                
                                <option value="DO">Dominican Republic</option>
                                
                                <option value="EC">Ecuador</option>
                                
                                <option value="EG">Egypt</option>
                                
                                <option value="SV">El Salvador</option>
                                
                                <option value="GQ">Equatorial Guinea</option>
                                
                                <option value="ER">Eritrea</option>
                                
                                <option value="EE">Estonia</option>
                                
                                <option value="SZ">Eswatini</option>
                                
                                <option value="ET">Ethiopia</option>
                                
                                <option value="FK">Falkland Islands (Malvinas)</option>
                                
                                <option value="FO">Faroe Islands</option>
                                
                                <option value="FJ">Fiji</option>
                                
                                <option value="FI">Finland</option>
                                
                                <option value="FR">France</option>
                                
                                <option value="GF">French Guiana</option>
                                
                                <option value="PF">French Polynesia</option>
                                
                                <option value="TF">French Southern Territories</option>
                                
                                <option value="GA">Gabon</option>
                                
                                <option value="GM">Gambia</option>
                                
                                <option value="GE">Georgia</option>
                                
                                <option value="DE">Germany</option>
                                
                                <option value="GH">Ghana</option>
                                
                                <option value="GI">Gibraltar</option>
                                
                                <option value="GR">Greece</option>
                                
                                <option value="GL">Greenland</option>
                                
                                <option value="GD">Grenada</option>
                                
                                <option value="GP">Guadeloupe</option>
                                
                                <option value="GU">Guam</option>
                                
                                <option value="GT">Guatemala</option>
                                
                                <option value="GG">Guernsey</option>
                                
                                <option value="GN">Guinea</option>
                                
                                <option value="GW">Guinea-Bissau</option>
                                
                                <option value="GY">Guyana</option>
                                
                                <option value="HT">Haiti</option>
                                
                                <option value="HM">Heard Island and McDonald Islands</option>
                                
                                <option value="VA">Holy See</option>
                                
                                <option value="HN">Honduras</option>
                                
                                <option value="HK">Hong Kong</option>
                                
                                <option value="HU">Hungary</option>
                                
                                <option value="IS">Iceland</option>
                                
                                <option value="IN">India</option>
                                
                                <option value="ID">Indonesia</option>
                                
                                <option value="IR">Iran</option>
                                
                                <option value="IQ">Iraq</option>
                                
                                <option value="IE">Ireland</option>
                                
                                <option value="IM">Isle of Man</option>
                                
                                <option value="IL">Israel</option>
                                
                                <option value="IT">Italy</option>
                                
                                <option value="JM">Jamaica</option>
                                
                                <option value="JP">Japan</option>
                                
                                <option value="JE">Jersey</option>
                                
                                <option value="JO">Jordan</option>
                                
                                <option value="KZ">Kazakhstan</option>
                                
                                <option value="KE">Kenya</option>
                                
                                <option value="KI">Kiribati</option>
                                
                                <option value="KW">Kuwait</option>
                                
                                <option value="KG">Kyrgyzstan</option>
                                
                                <option value="LA">Laos</option>
                                
                                <option value="LV">Latvia</option>
                                
                                <option value="LB">Lebanon</option>
                                
                                <option value="LS">Lesotho</option>
                                
                                <option value="LR">Liberia</option>
                                
                                <option value="LY">Libya</option>
                                
                                <option value="LI">Liechtenstein</option>
                                
                                <option value="LT">Lithuania</option>
                                
                                <option value="LU">Luxembourg</option>
                                
                                <option value="MO">Macao</option>
                                
                                <option value="MG">Madagascar</option>
                                
                                <option value="MW">Malawi</option>
                                
                                <option value="MY">Malaysia</option>
                                
                                <option value="MV">Maldives</option>
                                
                                <option value="ML">Mali</option>
                                
                                <option value="MT">Malta</option>
                                
                                <option value="MH">Marshall Islands</option>
                                
                                <option value="MQ">Martinique</option>
                                
                                <option value="MR">Mauritania</option>
                                
                                <option value="MU">Mauritius</option>
                                
                                <option value="YT">Mayotte</option>
                                
                                <option value="MX">Mexico</option>
                                
                                <option value="FM">Micronesia (Federated States of)</option>
                                
                                <option value="MD">Moldova</option>
                                
                                <option value="MC">Monaco</option>
                                
                                <option value="MN">Mongolia</option>
                                
                                <option value="ME">Montenegro</option>
                                
                                <option value="MS">Montserrat</option>
                                
                                <option value="MA">Morocco</option>
                                
                                <option value="MZ">Mozambique</option>
                                
                                <option value="MM">Myanmar</option>
                                
                                <option value="NA">Namibia</option>
                                
                                <option value="NR">Nauru</option>
                                
                                <option value="NP">Nepal</option>
                                
                                <option value="NL">Netherlands</option>
                                
                                <option value="NC">New Caledonia</option>
                                
                                <option value="NZ">New Zealand</option>
                                
                                <option value="NI">Nicaragua</option>
                                
                                <option value="NE">Niger</option>
                                
                                <option value="NG">Nigeria</option>
                                
                                <option value="NU">Niue</option>
                                
                                <option value="NF">Norfolk Island</option>
                                
                                <option value="KP">North Korea</option>
                                
                                <option value="MK">North Macedonia</option>
                                
                                <option value="MP">Northern Mariana Islands</option>
                                
                                <option value="NO">Norway</option>
                                
                                <option value="OM">Oman</option>
                                
                                <option value="PK">Pakistan</option>
                                
                                <option value="PW">Palau</option>
                                
                                <option value="PS">Palestine, State of</option>
                                
                                <option value="PA">Panama</option>
                                
                                <option value="PG">Papua New Guinea</option>
                                
                                <option value="PY">Paraguay</option>
                                
                                <option value="PE">Peru</option>
                                
                                <option value="PH">Philippines</option>
                                
                                <option value="PN">Pitcairn</option>
                                
                                <option value="PL">Poland</option>
                                
                                <option value="PT">Portugal</option>
                                
                                <option value="PR">Puerto Rico</option>
                                
                                <option value="QA">Qatar</option>
                                
                                <option value="RE">Réunion</option>
                                
                                <option value="RO">Romania</option>
                                
                                <option value="RU">Russia</option>
                                
                                <option value="RW">Rwanda</option>
                                
                                <option value="BL">Saint Barthélemy</option>
                                
                                <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                
                                <option value="KN">Saint Kitts and Nevis</option>
                                
                                <option value="LC">Saint Lucia</option>
                                
                                <option value="MF">Saint Martin (French part)</option>
                                
                                <option value="PM">Saint Pierre and Miquelon</option>
                                
                                <option value="VC">Saint Vincent and the Grenadines</option>
                                
                                <option value="WS">Samoa</option>
                                
                                <option value="SM">San Marino</option>
                                
                                <option value="ST">Sao Tome and Principe</option>
                                
                                <option value="SA">Saudi Arabia</option>
                                
                                <option value="SN">Senegal</option>
                                
                                <option value="RS">Serbia</option>
                                
                                <option value="SC">Seychelles</option>
                                
                                <option value="SL">Sierra Leone</option>
                                
                                <option value="SG">Singapore</option>
                                
                                <option value="SX">Sint Maarten (Dutch part)</option>
                                
                                <option value="SK">Slovakia</option>
                                
                                <option value="SI">Slovenia</option>
                                
                                <option value="SB">Solomon Islands</option>
                                
                                <option value="SO">Somalia</option>
                                
                                <option value="ZA">South Africa</option>
                                
                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                
                                <option value="KR">South Korea</option>
                                
                                <option value="SS">South Sudan</option>
                                
                                <option value="ES">Spain</option>
                                
                                <option value="LK">Sri Lanka</option>
                                
                                <option value="SD">Sudan</option>
                                
                                <option value="SR">Suriname</option>
                                
                                <option value="SJ">Svalbard and Jan Mayen</option>
                                
                                <option value="SE">Sweden</option>
                                
                                <option value="CH">Switzerland</option>
                                
                                <option value="SY">Syria</option>
                                
                                <option value="TW">Taiwan</option>
                                
                                <option value="TJ">Tajikistan</option>
                                
                                <option value="TZ">Tanzania</option>
                                
                                <option value="TH">Thailand</option>
                                
                                <option value="TL">Timor-Leste</option>
                                
                                <option value="TG">Togo</option>
                                
                                <option value="TK">Tokelau</option>
                                
                                <option value="TO">Tonga</option>
                                
                                <option value="TT">Trinidad and Tobago</option>
                                
                                <option value="TN">Tunisia</option>
                                
                                <option value="TR">Turkey</option>
                                
                                <option value="TM">Turkmenistan</option>
                                
                                <option value="TC">Turks and Caicos Islands</option>
                                
                                <option value="TV">Tuvalu</option>
                                
                                <option value="UG">Uganda</option>
                                
                                <option value="UA">Ukraine</option>
                                
                                <option value="AE">United Arab Emirates</option>
                                
                                <option value="GB">United Kingdom</option>
                                
                                <option value="UM">United States Minor Outlying Islands</option>
                                
                                <option value="US">United States of America</option>
                                
                                <option value="UY">Uruguay</option>
                                
                                <option value="UZ">Uzbekistan</option>
                                
                                <option value="VU">Vanuatu</option>
                                
                                <option value="VE">Venezuela</option>
                                
                                <option value="VN">Vietnam</option>
                                
                                <option value="VG">Virgin Islands (British)</option>
                                
                                <option value="VI">Virgin Islands (U.S.)</option>
                                
                                <option value="WF">Wallis and Futuna</option>
                                
                                <option value="EH">Western Sahara</option>
                                
                                <option value="YE">Yemen</option>
                                
                                <option value="ZM">Zambia</option>
                                
                                <option value="ZW">Zimbabwe</option>
                                
                                </select>
                            </div>
                            
                            <div class=" my-4">
                                <label for="id_customer-dual_nationality" class="text-gray-700 text-left text-sm" >Gender<span class="text-red-600">*</span></label>
                                <select placeholder="Select a Country" class="form-select form-select-lg mb-3
                                    appearance-none
                                    block
                                    w-full
                                    px-4
                                    py-2
                                    text-xl
                                    font-normal
                                    text-gray-700
                                    bg-white bg-clip-padding bg-no-repeat
                                    border border-solid border-gray-300
                                    rounded
                                    transition
                                    ease-in-out
                                    m-0
                                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" v-model="user.gender" id="id_customer-dual_nationality">
                                <option :value=null disabled>-</option>
                                
                                <option value="Male">Male</option>
                                
                                <option value="Female">Female</option>
                                
                                </select>
                            </div>
                            <div class="form-floating my-4">
                                        <input v-model="user.phone_number" type="tel" class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                                        placeholder="Mobile Number" id="floatingInput5" />
                                        <label for="floatingInput5" class="text-gray-700" >Mobile Number<span class="text-red-600">*</span></label>
                            </div>

                        </div>

                    <div class="py-6 flex w-full">
                        <div class=" space-y-2">
                            <div v-if="editProf" class=" flex justify-items-start">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>
                                <p class="font-medium text-t3-mutedText pl-3 mb-2">{{customer.nationality}}</p>
                            </div>
                            <div v-if="editProf" class=" flex justify-items-start">
                                <img src="../assets/gender.svg" class="h-6 w-6 text-t3-mutedText object-cover"/>
                                <p class="font-medium text-t3-mutedText pl-3 mb-2">{{customer.gender}}</p>
                            </div>
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
</svg>

                                    
                                <select v-model="customerProf.country_of_issue" :disabled="editProf" class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                <option value="" disabled>Select country of issue</option>
                                <option :value="null" disabled>Select country of issue</option>
                                <option value="AF">Afghanistan</option>
                                
                                <option value="AX">Åland Islands</option>
                                
                                <option value="AL">Albania</option>
                                
                                <option value="DZ">Algeria</option>
                                
                                <option value="AS">American Samoa</option>
                                
                                <option value="AD">Andorra</option>
                                
                                <option value="AO">Angola</option>
                                
                                <option value="AI">Anguilla</option>
                                
                                <option value="AQ">Antarctica</option>
                                
                                <option value="AG">Antigua and Barbuda</option>
                                
                                <option value="AR">Argentina</option>
                                
                                <option value="AM">Armenia</option>
                                
                                <option value="AW">Aruba</option>
                                
                                <option value="AU">Australia</option>
                                
                                <option value="AT">Austria</option>
                                
                                <option value="AZ">Azerbaijan</option>
                                
                                <option value="BS">Bahamas</option>
                                
                                <option value="BH">Bahrain</option>
                                
                                <option value="BD">Bangladesh</option>
                                
                                <option value="BB">Barbados</option>
                                
                                <option value="BY">Belarus</option>
                                
                                <option value="BE">Belgium</option>
                                
                                <option value="BZ">Belize</option>
                                
                                <option value="BJ">Benin</option>
                                
                                <option value="BM">Bermuda</option>
                                
                                <option value="BT">Bhutan</option>
                                
                                <option value="BO">Bolivia</option>
                                
                                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                
                                <option value="BA">Bosnia and Herzegovina</option>
                                
                                <option value="BW">Botswana</option>
                                
                                <option value="BV">Bouvet Island</option>
                                
                                <option value="BR">Brazil</option>
                                
                                <option value="IO">British Indian Ocean Territory</option>
                                
                                <option value="BN">Brunei</option>
                                
                                <option value="BG">Bulgaria</option>
                                
                                <option value="BF">Burkina Faso</option>
                                
                                <option value="BI">Burundi</option>
                                
                                <option value="CV">Cabo Verde</option>
                                
                                <option value="KH">Cambodia</option>
                                
                                <option value="CM">Cameroon</option>
                                
                                <option value="CA">Canada</option>
                                
                                <option value="KY">Cayman Islands</option>
                                
                                <option value="CF">Central African Republic</option>
                                
                                <option value="TD">Chad</option>
                                
                                <option value="CL">Chile</option>
                                
                                <option value="CN">China</option>
                                
                                <option value="CX">Christmas Island</option>
                                
                                <option value="CC">Cocos (Keeling) Islands</option>
                                
                                <option value="CO">Colombia</option>
                                
                                <option value="KM">Comoros</option>
                                
                                <option value="CG">Congo</option>
                                
                                <option value="CD">Congo (the Democratic Republic of the)</option>
                                
                                <option value="CK">Cook Islands</option>
                                
                                <option value="CR">Costa Rica</option>
                                
                                <option value="CI">Côte d&#x27;Ivoire</option>
                                
                                <option value="HR">Croatia</option>
                                
                                <option value="CU">Cuba</option>
                                
                                <option value="CW">Curaçao</option>
                                
                                <option value="CY">Cyprus</option>
                                
                                <option value="CZ">Czechia</option>
                                
                                <option value="DK">Denmark</option>
                                
                                <option value="DJ">Djibouti</option>
                                
                                <option value="DM">Dominica</option>
                                
                                <option value="DO">Dominican Republic</option>
                                
                                <option value="EC">Ecuador</option>
                                
                                <option value="EG">Egypt</option>
                                
                                <option value="SV">El Salvador</option>
                                
                                <option value="GQ">Equatorial Guinea</option>
                                
                                <option value="ER">Eritrea</option>
                                
                                <option value="EE">Estonia</option>
                                
                                <option value="SZ">Eswatini</option>
                                
                                <option value="ET">Ethiopia</option>
                                
                                <option value="FK">Falkland Islands (Malvinas)</option>
                                
                                <option value="FO">Faroe Islands</option>
                                
                                <option value="FJ">Fiji</option>
                                
                                <option value="FI">Finland</option>
                                
                                <option value="FR">France</option>
                                
                                <option value="GF">French Guiana</option>
                                
                                <option value="PF">French Polynesia</option>
                                
                                <option value="TF">French Southern Territories</option>
                                
                                <option value="GA">Gabon</option>
                                
                                <option value="GM">Gambia</option>
                                
                                <option value="GE">Georgia</option>
                                
                                <option value="DE">Germany</option>
                                
                                <option value="GH">Ghana</option>
                                
                                <option value="GI">Gibraltar</option>
                                
                                <option value="GR">Greece</option>
                                
                                <option value="GL">Greenland</option>
                                
                                <option value="GD">Grenada</option>
                                
                                <option value="GP">Guadeloupe</option>
                                
                                <option value="GU">Guam</option>
                                
                                <option value="GT">Guatemala</option>
                                
                                <option value="GG">Guernsey</option>
                                
                                <option value="GN">Guinea</option>
                                
                                <option value="GW">Guinea-Bissau</option>
                                
                                <option value="GY">Guyana</option>
                                
                                <option value="HT">Haiti</option>
                                
                                <option value="HM">Heard Island and McDonald Islands</option>
                                
                                <option value="VA">Holy See</option>
                                
                                <option value="HN">Honduras</option>
                                
                                <option value="HK">Hong Kong</option>
                                
                                <option value="HU">Hungary</option>
                                
                                <option value="IS">Iceland</option>
                                
                                <option value="IN">India</option>
                                
                                <option value="ID">Indonesia</option>
                                
                                <option value="IR">Iran</option>
                                
                                <option value="IQ">Iraq</option>
                                
                                <option value="IE">Ireland</option>
                                
                                <option value="IM">Isle of Man</option>
                                
                                <option value="IL">Israel</option>
                                
                                <option value="IT">Italy</option>
                                
                                <option value="JM">Jamaica</option>
                                
                                <option value="JP">Japan</option>
                                
                                <option value="JE">Jersey</option>
                                
                                <option value="JO">Jordan</option>
                                
                                <option value="KZ">Kazakhstan</option>
                                
                                <option value="KE">Kenya</option>
                                
                                <option value="KI">Kiribati</option>
                                
                                <option value="KW">Kuwait</option>
                                
                                <option value="KG">Kyrgyzstan</option>
                                
                                <option value="LA">Laos</option>
                                
                                <option value="LV">Latvia</option>
                                
                                <option value="LB">Lebanon</option>
                                
                                <option value="LS">Lesotho</option>
                                
                                <option value="LR">Liberia</option>
                                
                                <option value="LY">Libya</option>
                                
                                <option value="LI">Liechtenstein</option>
                                
                                <option value="LT">Lithuania</option>
                                
                                <option value="LU">Luxembourg</option>
                                
                                <option value="MO">Macao</option>
                                
                                <option value="MG">Madagascar</option>
                                
                                <option value="MW">Malawi</option>
                                
                                <option value="MY">Malaysia</option>
                                
                                <option value="MV">Maldives</option>
                                
                                <option value="ML">Mali</option>
                                
                                <option value="MT">Malta</option>
                                
                                <option value="MH">Marshall Islands</option>
                                
                                <option value="MQ">Martinique</option>
                                
                                <option value="MR">Mauritania</option>
                                
                                <option value="MU">Mauritius</option>
                                
                                <option value="YT">Mayotte</option>
                                
                                <option value="MX">Mexico</option>
                                
                                <option value="FM">Micronesia (Federated States of)</option>
                                
                                <option value="MD">Moldova</option>
                                
                                <option value="MC">Monaco</option>
                                
                                <option value="MN">Mongolia</option>
                                
                                <option value="ME">Montenegro</option>
                                
                                <option value="MS">Montserrat</option>
                                
                                <option value="MA">Morocco</option>
                                
                                <option value="MZ">Mozambique</option>
                                
                                <option value="MM">Myanmar</option>
                                
                                <option value="NA">Namibia</option>
                                
                                <option value="NR">Nauru</option>
                                
                                <option value="NP">Nepal</option>
                                
                                <option value="NL">Netherlands</option>
                                
                                <option value="NC">New Caledonia</option>
                                
                                <option value="NZ">New Zealand</option>
                                
                                <option value="NI">Nicaragua</option>
                                
                                <option value="NE">Niger</option>
                                
                                <option value="NG">Nigeria</option>
                                
                                <option value="NU">Niue</option>
                                
                                <option value="NF">Norfolk Island</option>
                                
                                <option value="KP">North Korea</option>
                                
                                <option value="MK">North Macedonia</option>
                                
                                <option value="MP">Northern Mariana Islands</option>
                                
                                <option value="NO">Norway</option>
                                
                                <option value="OM">Oman</option>
                                
                                <option value="PK">Pakistan</option>
                                
                                <option value="PW">Palau</option>
                                
                                <option value="PS">Palestine, State of</option>
                                
                                <option value="PA">Panama</option>
                                
                                <option value="PG">Papua New Guinea</option>
                                
                                <option value="PY">Paraguay</option>
                                
                                <option value="PE">Peru</option>
                                
                                <option value="PH">Philippines</option>
                                
                                <option value="PN">Pitcairn</option>
                                
                                <option value="PL">Poland</option>
                                
                                <option value="PT">Portugal</option>
                                
                                <option value="PR">Puerto Rico</option>
                                
                                <option value="QA">Qatar</option>
                                
                                <option value="RE">Réunion</option>
                                
                                <option value="RO">Romania</option>
                                
                                <option value="RU">Russia</option>
                                
                                <option value="RW">Rwanda</option>
                                
                                <option value="BL">Saint Barthélemy</option>
                                
                                <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                
                                <option value="KN">Saint Kitts and Nevis</option>
                                
                                <option value="LC">Saint Lucia</option>
                                
                                <option value="MF">Saint Martin (French part)</option>
                                
                                <option value="PM">Saint Pierre and Miquelon</option>
                                
                                <option value="VC">Saint Vincent and the Grenadines</option>
                                
                                <option value="WS">Samoa</option>
                                
                                <option value="SM">San Marino</option>
                                
                                <option value="ST">Sao Tome and Principe</option>
                                
                                <option value="SA">Saudi Arabia</option>
                                
                                <option value="SN">Senegal</option>
                                
                                <option value="RS">Serbia</option>
                                
                                <option value="SC">Seychelles</option>
                                
                                <option value="SL">Sierra Leone</option>
                                
                                <option value="SG">Singapore</option>
                                
                                <option value="SX">Sint Maarten (Dutch part)</option>
                                
                                <option value="SK">Slovakia</option>
                                
                                <option value="SI">Slovenia</option>
                                
                                <option value="SB">Solomon Islands</option>
                                
                                <option value="SO">Somalia</option>
                                
                                <option value="ZA">South Africa</option>
                                
                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                
                                <option value="KR">South Korea</option>
                                
                                <option value="SS">South Sudan</option>
                                
                                <option value="ES">Spain</option>
                                
                                <option value="LK">Sri Lanka</option>
                                
                                <option value="SD">Sudan</option>
                                
                                <option value="SR">Suriname</option>
                                
                                <option value="SJ">Svalbard and Jan Mayen</option>
                                
                                <option value="SE">Sweden</option>
                                
                                <option value="CH">Switzerland</option>
                                
                                <option value="SY">Syria</option>
                                
                                <option value="TW">Taiwan</option>
                                
                                <option value="TJ">Tajikistan</option>
                                
                                <option value="TZ">Tanzania</option>
                                
                                <option value="TH">Thailand</option>
                                
                                <option value="TL">Timor-Leste</option>
                                
                                <option value="TG">Togo</option>
                                
                                <option value="TK">Tokelau</option>
                                
                                <option value="TO">Tonga</option>
                                
                                <option value="TT">Trinidad and Tobago</option>
                                
                                <option value="TN">Tunisia</option>
                                
                                <option value="TR">Turkey</option>
                                
                                <option value="TM">Turkmenistan</option>
                                
                                <option value="TC">Turks and Caicos Islands</option>
                                
                                <option value="TV">Tuvalu</option>
                                
                                <option value="UG">Uganda</option>
                                
                                <option value="UA">Ukraine</option>
                                
                                <option value="AE">United Arab Emirates</option>
                                
                                <option value="GB">United Kingdom</option>
                                
                                <option value="UM">United States Minor Outlying Islands</option>
                                
                                <option value="US">United States of America</option>
                                
                                <option value="UY">Uruguay</option>
                                
                                <option value="UZ">Uzbekistan</option>
                                
                                <option value="VU">Vanuatu</option>
                                
                                <option value="VE">Venezuela</option>
                                
                                <option value="VN">Vietnam</option>
                                
                                <option value="VG">Virgin Islands (British)</option>
                                
                                <option value="VI">Virgin Islands (U.S.)</option>
                                
                                <option value="WF">Wallis and Futuna</option>
                                
                                <option value="EH">Western Sahara</option>
                                
                                <option value="YE">Yemen</option>
                                
                                <option value="ZM">Zambia</option>
                                
                                <option value="ZW">Zimbabwe</option>
                                
                                </select>
                                  

                            </div>
                            <div class=" flex justify-items-start items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                    </svg>
                                    <select v-model="customerProf.customer_company" :disabled="editProf" class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                        <option :value="null"> Add Organization*</option>
                                        <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{organization.organization_name}}</option>
                                    </select>
                            </div>
                            
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-t3-mutedText">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                </svg>
                                                <div class="w-56 py-1.5 flex flex-col items-center mx-auto">
                                        <div class="w-full ">
                                            <div class="flex flex-col items-center relative">
                                                <div class="w-full  ">
                                                    <div @click="showAff = !showAff" :class="{'hover:bg-sky-200 hover:bg-sky-200  hover:text-t3-secondary border cursor-pointer':!editProf}" class=" text-t3 items-center p-2 flex  border-gray-200 bg-white rounded justify-between ">
                                                        <p >{{ customerProf.other_affiliations.length }} Selected</p>
                                                        <svg v-if="!editProf" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-3 h-3 transform" :class="{'rotate-180':showAff}">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                        </svg>

                                                    </div>
                                                </div>
                                                <div v-if="!editProf && showAff" class="shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
                                                    <div  v-for="organization in organizations" :key="organization.id" class="flex flex-col w-full" >
                                                        <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-t3-body hover:text-t3-secondary">
                                                            <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                                                <div class="w-full items-center flex">
                                                                    <input  @change="editChange(organization.id)" :checked=" customerProf.other_affiliations.find(a => a === organization.id)" class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox"  id="flexCheckDefault"/>
                                                                    <div class="mx-2 leading-6  ">{{organization.organization_name}} </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- <link href="https://cdn.jsdelivr.net/npm/@tailwindcss/custom-forms@0.2.1/dist/custom-forms.css" rel="stylesheet" />
                                <select v-if="!editProf" v-model="customerProf.other_affiliations" :disabled="editProf" class="form-multiselect block w-56 px-3 py-1.5 text-base font-normal mt-6 text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" multiple >
                                <option v-for="organization in organizations" :key="organization.id" :value="organization.id">{{organization.organization_name}}</option>
                                </select> -->
                                <!-- <div v-if="editProf" class=" w-56 px-3 py-1.5 flex items-center">
                                    <p v-if="customerProf.other_affiliations === null" class="font-medium whitespace-nowrap text-t3-mutedText pl-2">Add Other Affiliations</p>
                                     
                                </div> -->
                               
                            </div>
                            
                        </div>

                        <div class="ml-64 space-y-2">
                            <div v-if="editProf" class=" flex justify-items-start">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                                <p class="font-medium text-t3-mutedText pl-3 mb-2">{{customer.email}}</p>
                            </div>
                            <div v-if="editProf" class=" flex justify-items-start">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                                </svg>
                                <p class="font-medium text-t3-mutedText pl-3 mb-2">{{customer.dob}}</p>
                            </div>
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3-mutedText w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>
                                    <input type="text" v-model="customerProf.license_number" placeholder="Add license number" :disabled="editProf" class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                            </div>
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class=" text-t3-mutedText w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                </svg>
                                <select v-model="customerProf.license_type" :disabled="editProf" class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                    <option :value="null" disabled> Add License Type *</option>
                                    <option v-for="typ in types" :key="typ.id" :value="typ.id">{{typ.name}}</option>
                                </select>
                            </div>
                            <div class=" flex justify-items-start items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-t3-mutedText">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                                </svg>
                                <select v-model="customerProf.party" :disabled="editProf" class="form-control w-56 px-3 py-1.5 text-base font-normal text-t3 bg-white bg-clip-padding border  border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" :class="{'border-none disdrop':editProf}" >
                                    <option :value="null"> Choose Party *</option>
                                    <option value="individual">Individual</option>
                                    <option value="company">Company</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <h1 class="font-semibold text-t3">Assessments</h1>
                    <div class="flex w-full gap-12 my-6  overflow-x-auto snap-x" v-if="assessments.length !== 0">
                        <div v-for="assessment in assessments" :key="assessment.id" class="my-2">
                            <div class="w-80 p-6 snap-center border-t3-secondary shadow ">
                            <div class="flex items-center w-full">

                                <h1 v-if="assessment.status !== 'sealed'" class="text-2xl capitalize mb-6 text-t3">{{assessment.status}}</h1>
                                <h1 v-if="assessment.status === 'sealed'" class="text-2xl capitalize mb-6 text-t3">Completed (Sealed)</h1>
                                <div v-if="scopeCheck('view_all_assessments') || checkDisputeTime(assessment) && scopeCheck('raise_dispute')"
					role="img" aria-label="bookmark" class="ml-auto"> 
					<button
						class="dropdown-toggle py-2.5  text-t3 font-medium text-xs leading-tight uppercase hover:cursor-pointer hover:text-t3-teritiary focus:outline-none focus:ring-0  transition duration-150 ease-in-out flex items-center whitespace-nowrap"
						type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="currentColor" viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
						</svg>
					</button>
					<ul class="dropdown-menu min-w-max absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-t3 mt-1 hidden m-0 bg-clip-padding border-none"
						aria-labelledby="dropdownMenuButton1">
						<li v-if="scopeCheck('view_all_assessments')">
							<router-link :to="{name: 'AssessmentDetails', params: {id: assessment.id }}"  class="flex w-full text-xs 2xl:text-sm text-t3 hover:text-t3-secondary text-center items-center p-3 gap-1 ">
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                        <p class="text-center">View Assessment</p>
                                    </router-link>
						</li>
						<li  v-if="checkDisputeTime(assessment) && scopeCheck('raise_dispute')">
							<button @click="assessmentUP =  assessment.id"  type="button" data-bs-toggle="modal"
						data-bs-target="#raiseDisputeModal" class="flex w-full text-xs 2xl:text-sm text-t3-cancel hover:text-red-500 text-center items-center p-3 gap-1 ">
                                                            
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>
                                        <p class="text-center">Raise Dispute</p>
                                    </button>
						</li>
					</ul>
				</div>
                            </div>
                            
                            <div class="flex justify-between items-center">
                                <div>
                                    <h2 class="text-md text-t3-mutedText">Assigned to</h2>
                                    <div class="flex">
                                        <div v-if="assessment.ope" class="relative h-10 w-10 bg-cover bg-center rounded-md">
                                        <img :src="assessmentOPE(assessment.ope).profile_img" alt=""
                                            class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white dark:border-gray-700 shadow">
                                        </div>
                                        <div v-if="!assessment.ope" class="relative h-10 w-10 bg-cover bg-center rounded-md">
                                        <h2 class="text-xs text-t3-mutedText font-semibold text-center">No OPE</h2>
                                        </div>
                                        <div v-if="assessment.ele" class="relative h-10 w-10 bg-cover rounded-md ml-1">
                                            <img :src="assessmentELE(assessment.ele).profile_img" alt=""
                                                class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white dark:border-gray-700 shadow">
                                        </div>
                                        <div v-if="!assessment.ele" class="relative h-10 w-10 bg-cover bg-center rounded-md">
                                        <h2 class="text-xs text-t3-mutedText font-semibold text-center">No ELE</h2>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div>
                                    <p class="font-semibold text-t3">{{assessment.start_time}}</p>
                                    <p class="text-sm text-t3-mutedText">{{new Date(assessment.date).toLocaleDateString('en-GB',{year: 'numeric', month: 'short', day: 'numeric'})}}</p>
                                </div>
                            </div>
              
                            
                        </div>
                        
                        </div>
                    </div>
                    <div class="text-xl w-full font-semibold text-center text-t3 my-24" v-if="assessments.length === 0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>It appears you have encountered an error!! It appears this user has no assessments.</p>
                     </div>
                    <h1 class="font-semibold text-t3">Documents</h1>
                    <h1 v-if="loading" class="font-lighter text-center text-sm text-t3">Something awesome awaits</h1>
                    <ScaleLoader :loading="loading" color="#0E2D5B" height="3px" width="15px"></ScaleLoader> 
                    <div class="flex w-full" v-if="!loading">
                        <p @click="addDoc = true" v-if="addDoc === false" class="ml-auto font-medium cursor-pointer w-12 text-t3-teritiary ">+ Add</p>
                        <p @click="addDoc = false" v-if="addDoc === true" class="ml-auto font-medium cursor-pointer w-12 text-t3-cancel ">Cancel</p>
                    </div>
                            <div class="mb-4 overflow-hidden">
                                <table class="min-w-full">
                                <thead class="border-b">
                                    <tr>
                                    <th scope="col" class="text-sm font-bold text-t3 px-6 py-4 text-left">Name</th>
                                    <th scope="col" class="text-sm font-bold text-t3 px-6 py-4 text-left">Type</th>
                                    <th scope="col" class="text-sm font-bold text-t3 px-6 py-4 text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="document in documents" :key="document.id" class="border-b">
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{document.name}}</td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">{{document.data_type}}</td>
                                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            <div class="flex justify-between">
                                                <!-- <router-link :to="{name:'ViewDocument',params:{id:document.id}}" class="ml-auto">
                                                    View Document
                                                </router-link> -->
                                                <a :href="document.document" target="_blank" class="ml-auto">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-t3 w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                    </svg>
                                                </a>
                                                <svg @click="deleter(document.id)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 cursor-pointer ml-3" fill="none" viewBox="0 0 24 24" stroke="#990c53" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg> 
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="addDoc && !loading" class="border-b">
                                        <td>
                                            <input v-model="doc.name" type="text" class="form-control block w-56 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none" placeholder="Document name goes here"/>
                                        </td>
                                        <td class="text-sm text-gray-900 font-light px-3 py-4 whitespace-nowrap">
                                            <select  v-model="doc.data_type" class="w-56 form-control block px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none">
                                            <option disabled value="">Select Data Type</option>
                                            <option value="Identity documents">Identity documents</option>
                                            <option value="License">License/Other Certificates</option>
                                            <option value="Assessment Privacy Statement">Assessment Privacy Statement</option>
                                            <option value="Candidate Declaration">Candidate Declaration</option>
                                            <option value="Discount documents">Discount Documents</option>
                                            <option value="ELP Certificates">ELP Certificates</option>
                                            </select>
                                            </td>
                                            <td class="border-b flex items-center">
                                                 <FileInput id="inputfield" ref="fileupload" accept="pdf" v-model="doc.document" class=" my-6 form-control block w- px-3 py-1 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                                                <p @click="submitDocument()" class="mx-auto cursor-pointer text-md text-t3-secondary font-light px-3 py-4 whitespace-nowrap">
                                                Submit
                                                </p>
                                           </td>

                                    </tr>
                                </tbody>
                                </table>
                            </div>


             
                </div>

                <div class="w-full py-3 px-3 flex items-center justify-between border-gray-300">

                </div>
            </div>
        </div>
    </div>
</Transition>

</template>

<script>
    // import { watchEffect } from 'vue'
import {
        mapState,
        mapGetters,
        mapActions
    } from 'vuex'
import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
import FileInput from '@/components/FileInput.vue'
import DocumentServices from "../services/DocumentServices"
import AssessmentService from '../services/AssessmentsServices';
import router from '../router'
import UsersServices from '../services/UsersServices';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import _ from 'lodash';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { ref} from 'vue';
import addDays from 'date-fns/addDays';

    export default {
        props:{
            id: {
                type:String
            },
            is_customer:{
                type:String
            }
        },
        
        setup() {
        const date = ref(new Date());
         const highlightedDates = ref([
          addDays(new Date(), 1),
          addDays(new Date(), 2),
          addDays(new Date(), 3),
        ])
        
        return {
            date,
            highlightedDates
            
        }
        },
        data() {
            return {
                checker:false,
                loading:false,
                currentFile:null,
                load:true,
                addDoc:false,
                editProf:true,
                search: '',
                page:1,
                showAff:false,
				numberOfPages:10,
                assess:[],
                disputeReason:'',
                staffReason:'',
                assessmentUP:null,
                doc:{
                    name:null,
                    customer:this.id,
                    document:null,
                    data_type:"",
                },
                user:{
                    email:null,
                    profile_img:null,
                    first_name:null,
                    middle_name:null,
                    last_name:null,
                    nationality:null,
                    dob:null,
                    gender:null,
                    phone_number:null,
                },
                customerProf:{
                    license_type:null,
                    party:null,
                    customer_company:null,
                    other_affiliations:[],
                   country_of_issue:null,
                    license_number:null
                }
            }
        },
        components: {
            FileInput,
            ScaleLoader,
            Datepicker
        },
        methods: {

            ...mapActions({
                getcustomers: 'Users/getCustomersPage',
                getcustomer: 'Users/getCustomer',
                getAllStaff: 'Users/getAllStaff',
                searchcustomers: 'Users/searchCustomers',
                getAssessmentCustomer: 'Assessments/getAssessmentCustomer',
                getorganizations: 'Settings/getAllOrganizations',
                gettypes: 'Settings/getAllCustomerTypes',
                getdocuments: 'Documents/getDocuments',
                getAllSettings:'Settings/getAllSettings',
                cleardocuments: 'Documents/clearDocs'
            }),
            reqSend(){
                if(this.disputeReason && this.staffReason)
                {
                    let sendingObj = {
						reason_of_staff: this.staffReason,
                        reason_of_dispute:this.disputeReason,
                        type:"external",
                    }
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    AssessmentService.raiseDispute(this.assessmentUP,getFormData(sendingObj)).then(()=>{
                                Swal.fire({
                                icon:"success",
                                title:"Success!",
                                text:"Dispute raised!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                                this.disputeReason=null
                                this.assessmentUp = null
								this.$forceUpdate()
								document.getElementById('disputeClose').click()
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                    
                }
                else{
                    Swal.fire({
                                icon:"warning",
                                title:"Hold",
                                text:"Fill in all the required fields first",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timerProgressBar: true,
                                timer: 3000,})
                }
            },
            editChange(id){
        
        if(this.customerProf.other_affiliations.includes(id))
        {this.customerProf.other_affiliations.splice(this.customerProf.other_affiliations.indexOf(id),1)}
        else{
          this.customerProf.other_affiliations.push(id)
          
        }
        
      },
      checkDisputeTime(assessment){
				let todays = new Date().getTime()/(3600000*24)
                    let checkd = (new Date(assessment.completion_time).getTime()/(3600000*24)) + this.settings.allow_customer_to_raise_dispute_within
                    if((assessment.status === 'completed' || assessment.status === 'sealed') && todays <= checkd){
                        return true
                    }
                    else{
                        return false
                    }
			},
            submitDocument(){
                this.loading=true
                const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                if (Array.isArray(val)) {
                val.forEach(v => fd.append(key, v))
                } else {
                fd.append(key, val)
                }
                return fd
                }, new FormData())
                DocumentServices.sendDocument(getFormData(this.doc)).then(()=>{
                    Swal.fire({
                                        icon:"success",
                                        title:"Success!",
                                        text:"The document was successfully uploaded",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                      })
                    this.getdocuments(this.id).then(()=>{
                        this.loading = false
                    })
                    this.$forceUpdate()
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                                      this.loading = false
                })
                
            },
            cancelProf(){
                this.editProf= true
                if(this.customer.customerprofile){
                    this.customerProf = {
                                                license_type:this.customer.customerprofile.license_type,
                    party:this.customer.customerprofile.party,
                    customer_company:this.customer.customerprofile.customer_company,
                    other_affiliations:this.customer.customerprofile.other_affiliations,
                   country_of_issue:this.customer.customerprofile.country_of_issue,
                    license_number:this.customer.customerprofile.license_number
                    }
                    this.$forceUpdate
                }
                else{
                    this.customerProf = {
                    license_type:null,
                    party:null,
                    customer_company:null,
                    other_affiliations:[],
                   country_of_issue:null,
                    license_number:null
                        }
                }
                
            },
            scopeCheck(scope){
				let user= JSON.parse(localStorage.getItem('userdetail'))
				

				if(user.is_company){
				return true;
				}else{
				return localStorage.getItem('scopes').includes(scope)}
			}	
            , changeUser(ided){
                this.load=false
                router.push({name: 'UserDetails', params: {id: ided , is_customer: this.is_customer}})
            this.getcustomer(ided).then(()=>{
                this.user.email=this.customer.email
                    this.user.first_name=this.customer.first_name
                    this.user.middle_name=this.customer.middle_name
                    this.user.last_name=this.customer.last_name
                    this.user.dob=this.customer.dob
                    this.user.gender=this.customer.gender
                    this.user.phone_number=this.customer.phone_number
                this.getAssessmentCustomer(this.id).then(()=>{
                    if(this.customer.customerprofile){this.customerProf = {
                                                license_type:this.customer.customerprofile.license_type,
                    party:this.customer.customerprofile.party,
                    customer_company:this.customer.customerprofile.customer_company,
                    other_affiliations:this.customer.customerprofile.other_affiliations,
                   country_of_issue:this.customer.customerprofile.country_of_issue,
                    license_number:this.customer.customerprofile.license_number
                    }}
                    else{
                        this.customerProf = {
                    license_type:null,
                    party:null,
                    customer_company:null,
                    other_affiliations:[],
                   country_of_issue:null,
                    license_number:null
                        }
                    }
                    this.load=true
                    this.cleardocuments().then(()=>{
                        this.doc={
                            name:null,
                            customer:this.id,
                            document:null,
                            data_type:null,
                        }
                        this.getdocuments(this.id)
                        this.$forceUpdate
                        }) 
                        })
            })
            this.addDoc=false
            
            this.$forceUpdate
            
            },
            filteredImage(cust){
            if(cust.profile_img){
                return cust.profile_img
            }
            else{
                return "https://i.pinimg.com/originals/e5/91/dc/e591dc82326cc4c86578e3eeecced792.png"
            }
        },
        deleter(id){
                    Swal.fire({
							icon: "warning",
							title: "Hold",
							text: "Are you sure you want to remove this document?",
							showConfirmButton: true,
                            showDenyButton: true,
                            confirmButtonColor: '#0E2D5B',
                            denyButtonColor: '#990c53',
						}).then((result)=>{
                            if(result.value){
                           DocumentServices.deleteDocument(id).then(()=>{
                                      Swal.fire({
                                        icon:"success",
                                        title:"Success!",
                                        text:"The document was successfully deleted",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                      })
                                    this.getdocuments(this.id)
                                     this.$forceUpdate()
                                    })
                            }
                        })
            },
            assessmentOPE(sentID){
				return this.allOPE.find(cus => cus.id === sentID)
			},
						assessmentELE(sentID){
				return this.allELE.find(cus => cus.id === sentID)
			},
            nextPage(){
				this.page++
				this.getcustomers({id:this.page, number:this.numberOfPages})
			},
			lastPage(){
				this.page=this.pageCount
				this.getcustomers({id:this.page, number:this.numberOfPages})
			},
			previousPage(){
				this.page--
				this.getcustomers({id:this.page, number:this.numberOfPages})
			},
			firstPage(){
				this.page=1
				this.getcustomers({id:this.page, number:this.numberOfPages})
			},
			refreshPage(){
				this.page = 1
				this.getcustomers({id:this.page, number:this.numberOfPages})
			},
            debCheck: _.debounce(function(){

                if(this.searchValue === ""|| this.searchValue === null)
                {
                this.getcustomers({id:this.page, number:this.numberOfPages})
                }
                else{
                    this.searchcustomers({input:this.search, number:this.numberOfPages})
                }
                }, 700),
                addUser(){
               this.checker = true
               let sender = this.user
                    if(!sender.nationality){
                        delete sender.nationality
                    }
                    if(!sender.profile_img){
                        delete sender.profile_img
                    }
               for (var i in this.user){
                    if(this.user[i] === null){
                        this.checker = false
                    }


                }
                if(this.checker){
                    const getFormData = object => Object.entries(object).reduce((fd, [ key, val ]) => {
                    if (Array.isArray(val)) {
                    val.forEach(v => fd.append(key, v))
                    } else {
                    fd.append(key, val)
                    }
                    return fd
                    }, new FormData())
                    this.user.dob = new Date(this.user.dob).toLocaleDateString('en-GB',{year: 'numeric', month: 'numeric', day: 'numeric'}).split('/').reverse().join('-');
                    UsersServices.updateCustomer(getFormData(this.user)).then(()=>{
                    Swal.fire({
                    icon:"success",
                    title:"Success!",
                    text:"User added successfully!",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                    }).catch((error)=>{
                        let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                    })
                }
                else{
                    Swal.fire({
                    icon:"warning",
                    title:"Try again",
                    text:"Please fill in all required the fields.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                            
                        }
            },
            attachmentLoad(event){
            var reader = new FileReader();
        reader.onload = function()
        {
          var output = document.getElementById('output-image');
          output.setAttribute("src",URL.createObjectURL(event.target.files[0]))
          document.getElementById('output-image').load();
        }
        reader.readAsDataURL(event.target.files[0]);
        
        this.currentFile=event.target.value;
       
        },
            updateCustomerProfile(){
                
                if(this.customer.customerprofile)
                {
                  if(this.customerProf.license_type && this.customerProf.customer_company && this.customerProf.party){
                    UsersServices.updateCustomerProfile(this.customer.id, this.customerProf).then(()=>{
                    Swal.fire({
                                        icon:"success",
                                        title:"Success!",
                                        text:"The customer's profile info has been updated",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                      })
                                        this.getcustomer(this.id).then(()=>{
                                            this.getAssessmentCustomer(this.id).then(()=>{
                            this.customerProf = this.customer.customerprofile
                            })
                        })
                            this.addUser()
                            this.editProf=true
                            this.$forceUpdate()
                        }).catch((error)=>{
                            let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
                  }
                  else{
                    Swal.fire({
                    icon:"warning",
                    title:"Try again",
                    text:"Please fill in all required the fields.",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 3000,})
                  }
                }
                else{
                    UsersServices.addCustomerProfile(this.customer.id, this.customerProf).then(()=>{
                    Swal.fire({
                                        icon:"success",
                                        title:"Success!",
                                        text:"The customer's profile info has been updated",
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timerProgressBar: true,
                                        timer: 3000,
                                      })
                                this.getcustomer(this.id).then(()=>{
                        this.getassessments().then(()=>{
                            if(this.customer.customerprofile){this.customerProf = {
                                                license_type:this.customer.customerprofile.license_type,
                    party:this.customer.customerprofile.party,
                    customer_company:this.customer.customerprofile.customer_company,
                    other_affiliations:this.customer.customerprofile.other_affiliations,
                   country_of_issue:this.customer.customerprofile.country_of_issue,
                    license_number:this.customer.customerprofile.license_number
                    }}
                            else{
                                this.customerProf = {
                            license_type:null,
                            party:null,
                            customer_company:null,
                            other_affiliations:null
                            
                                }
                            }
                            })})
                            this.addUser()
                            this.editProf=true
                    this.$forceUpdate()
                }).catch((error)=>{
                    let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
                })
                }
                
            }
        },

        computed: {
        
        profileImage(){
            if(this.customer.profile_img!== null){
                return this.customer.profile_img
            }
            else{
                return "https://i.pinimg.com/originals/e5/91/dc/e591dc82326cc4c86578e3eeecced792.png"
            }

        },
        ...mapGetters('Users', [
        'customerOnly',
        'staffOnly',
        'allOPE',
        'allELE'
      ]),
      
        pageCount(){
				if(this.counts/this.numberOfPages > 1){
					return Math.ceil(this.counts/this.numberOfPages)
				}
				else{
					return null
				}
			},
            ...mapState({
                customers: state => state.Users.customers,
                isNext: state => state.Users.next,
                counts: state => state.Users.count,
                customer: state => state.Users.customer,
                assessments: state => state.Assessments.assessmentCustomer,
                documents: state => state.Documents.documents,
                organizations: state => state.Settings.organizations,
                types: state => state.Settings.customer_types,
                settings: state => state.Settings.settings
            })
        },

        created() {
            this.getorganizations()
            this.gettypes()
            this.getAllStaff()
            this.getAllSettings()
            this.getcustomers({id:this.page, number:this.numberOfPages})
            this.getcustomer(this.id).then(()=>{
                
                    this.user.email=this.customer.email
                    this.user.first_name=this.customer.first_name
                    this.user.middle_name=this.customer.middle_name
                    this.user.last_name=this.customer.last_name
                    this.user.dob=this.customer.dob
                    this.user.gender=this.customer.gender
                    this.user.phone_number=this.customer.phone_number
                
                this.getAssessmentCustomer(this.id).then(()=>{
                    if(this.customer.customerprofile){this.customerProf = {
                        license_type:this.customer.customerprofile.license_type,
                    party:this.customer.customerprofile.party,
                    customer_company:this.customer.customerprofile.customer_company,
                    other_affiliations:this.customer.customerprofile.other_affiliations,
                   country_of_issue:this.customer.customerprofile.country_of_issue,
                    license_number:this.customer.customerprofile.license_number
                    }}
                    else{
                        this.customerProf = {
                    license_type:null,
                    party:null,
                    customer_company:null,
                    other_affiliations:[],
                   country_of_issue:null,
                    license_number:null
                        }
                    }
                    this.load=true
                    this.cleardocuments().then(()=>{
                        this.doc={
                            name:null,
                            customer:this.id,
                            document:null,
                            data_type:null,
                        }
                        this.getdocuments(this.id)
                        this.$forceUpdate
                        }) 
                        })
            })
           
    }
    }
</script>

<style scoped>
    .customH {
        max-height: 35rem;
    }
    .disdrop{
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		text-indent: 1px;
		text-overflow: '';
		border: none;
	}

</style>