import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/tailwind.css'; 
import 'tw-elements';
import ReportServices from './services/ReportServices';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
const app = createApp(App)

app.use(router).
use(store).
component(ReportServices,"ReportServices").
component('EasyDataTable', Vue3EasyDataTable).
mount('#app')
